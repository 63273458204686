import { useEffect, useState } from 'react'

import { ActionIconEnum } from 'common/enums/ActionIcon'
import { PageTitles } from 'common/enums/PageTitles'
import { ListDescription, Separator } from 'components'
import BaseBridge from 'config/bridge/BaseBridge'
import { CheckoutBaseBridge } from 'config/bridge/CheckoutBaseBridge'
import { useLocalErrorHandler } from 'hooks/useLocalErrorHandler'
import { useNavBar } from 'hooks/useNavBar'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { hasAnyTicket } from 'store/afterSales/handleTickets'
import { Icons } from 'utils/icons'
import { TestimonialsCarousel } from 'components/TestimonialsCarousel/TestimonialsCarousel'
import FeatureTopicList from 'components/FeatureTopic/FeatureTopicList'
import { testimonialsDataRandom } from 'components/TestimonialsCarousel/TestimonialsData'
import PrizeCounter from 'components/Counter/PrizeCounter'
import { PaymentTypeEnum } from 'common/enums/PaymentTypeEnum'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { Tag } from '@interco/inter-ui/components/Tag'
import { Text } from '@interco/inter-ui/components/Text'
import { formatToBRL } from '@interco/lib-util'

import { ReceiptProcessing } from '../Receipt/ReceiptProcessing/ReceiptProcessing'
import * as S from './Home.styles'
import { AnalyticsLandingPage } from './Home.tags'
import HomePayment from './HomePayment'

export const Home = () => {
  const history = useHistory()
  const handlerError = useLocalErrorHandler()
  const isLoading = useBoundState((state) => state.isLoading)

  const awards = useBoundState((state) => state.awards)
  const tickets = useBoundState((state) => state.tickets)
  const { enabled } = useBoundState((state) => state.eligibility)
  const formOfPayment = useBoundState((state) => state.formOfPayment)
  const emptyFormOfPayment = useBoundState((state) => state.emptyFormOfPayment)
  const getCheckoutResponse = useBoundState((state) => state.getCheckoutResponse)
  const isDarkMode = useBoundState((state) => state.isDarkMode)

  const SINGLE_TICKET_PRICE = 3
  const HAS_ANY_TICKET = hasAnyTicket(tickets)
  const DEFAULT_COUNTER_TOTAL = 0

  const counterTotal = +(awards.ACCUMULATED_PRIZE_VALUE || DEFAULT_COUNTER_TOTAL) / 100

  const [inWaitPayment, setInWaitPayment] = useState(false)
  const [eligibilityBS, setEligibilityBS] = useState(false)
  const [quantitySelected, setQuantitySelected] = useState(1)
  const [payout, setPayout] = useState(SINGLE_TICKET_PRICE)

  const canceledIcon = <Icons.Canceled width={32} height={32} color="var(--error500)" />

  useNavBar({
    navbarTitle: PageTitles.SCRATCHCARD,
    actionIcons: {
      rightIcon: ActionIconEnum.FAQ,
      leftIcon: ActionIconEnum.BACK,
    },
    backListener: () => BaseBridge.requestGoBack(),
  })

  useEffect(() => {
    AnalyticsLandingPage({ content_action: 'dado_carregado', adjust_event_token: '0' })
  }, [])

  const ticketIcons = {
    ticket: <Icons.Ticket width={24} height={24} color="var(--primary500)" />,
    right: <Icons.ChevronRight width={24} height={24} color="var(--primary500)" />,
  }

  const handleCheckout = async () => {
    if (!enabled) {
      setEligibilityBS(true)
      return
    }

    AnalyticsLandingPage({
      ref_type: 'botão',
      content_action: 'toque',
      action_id: `Pagar - ${formatToBRL(payout)}`,
      adjust_event_token: '0',
    })

    try {
      const result = await CheckoutBaseBridge.contractWallet(`${payout}`)

      emptyFormOfPayment()
      setInWaitPayment(true)

      getCheckoutResponse({ history, capitalizationLot: result.transactionId })
    } catch (error) {
      const errorMessage = error as Error

      if (errorMessage.message !== 'walletCheckoutManagerDidUserCancelISafeAuthentication') {
        handlerError(errorMessage, 'LandingPage.handleCheckout')
      }
    }
  }

  const renderMyTickets = (
    <ListDescription
      margin="24px 0 0"
      iconLeft={ticketIcons.ticket}
      iconRight={ticketIcons.right}
      withBorder
      onClick={() => history.push(TypesRoutes.AFTER_SALES)}
    >
      <Text variant="body-3" colorWeight={500} bold>
        Minhas Sortezinhas
      </Text>
    </ListDescription>
  )

  const openGeneralConditions = () => {
    AnalyticsLandingPage({ action_id: 'condições gerais', ref_type: 'botão' })
    BaseBridge.openPdf(
      'https://static.bancointer.com.br/seguros/capitalization/CondicoesGeraisSortezinha.pdf',
      'Condições Gerais',
    )
  }

  const openRegistrationForm = () => {
    AnalyticsLandingPage({ action_id: 'ficha de cadastro', ref_type: 'botão' })
    history.push(TypesRoutes.REGISTRATION_FORM)
  }

  const openLegalInfo = () => {
    AnalyticsLandingPage({ action_id: 'informações legais', ref_type: 'botão' })
    BaseBridge.openPdf(
      'https://static.bancointer.com.br/seguros/capitalization/InformacoesLegaisSortezinha.pdf',
      'Informações Legais',
    )
  }

  const renderEligibilityBS = eligibilityBS && (
    <BottomSheet noSwipe onClose={() => setEligibilityBS(false)}>
      <S.BottomSheetContent>
        {canceledIcon}
        <Text variant="headline-h3" as="h3" semiBold>
          Não foi possível concluir sua compra
        </Text>
        <Text variant="body-3">A compra da Sortezinha não está disponível para a sua conta.</Text>
        <Button fullWidth variant="primary" onClick={() => setEligibilityBS(false)}>
          Entendi
        </Button>
      </S.BottomSheetContent>
    </BottomSheet>
  )

  const renderStickFooter = (
    <>
      <Separator borderWidth="1px" />

      <S.Footer>
        <Button
          data-testid="pay-button"
          fullWidth
          disabled={!formOfPayment.type}
          isLoading={isLoading}
          onClick={handleCheckout}
        >
          {formOfPayment.type === PaymentTypeEnum.LOYALTY
            ? 'Pagar'
            : `Pagar - ${formatToBRL(payout)}`}
        </Button>

        <Text variant="caption-1" colorWeight={500}>
          Declaro estar de acordo com{' '}
          <S.Link onClick={openGeneralConditions}>Condições Gerais</S.Link>,{' '}
          <S.Link onClick={openLegalInfo}>Informações legais</S.Link> e{' '}
          <S.Link onClick={openRegistrationForm}>Ficha de Cadastro</S.Link> da Sortezinha.
        </Text>
      </S.Footer>
    </>
  )

  return inWaitPayment ? (
    <ReceiptProcessing />
  ) : (
    <S.Container>
      <S.Section>
        <S.SectionMargin>{HAS_ANY_TICKET && renderMyTickets}</S.SectionMargin>

        <PrizeCounter countNumber={counterTotal} isDarkMode={isDarkMode} />

        <S.SectionMargin>
          <S.Header>
            <Tag theme="green">Por apenas R$ 3,00</Tag>
            <Text variant="headline-h1" as="h1" semiBold>
              São centenas de pessoas premiadas por dia!
            </Text>
          </S.Header>

          <FeatureTopicList />

          <HomePayment
            key="homePayment"
            quantitySelected={quantitySelected}
            payout={payout}
            setQuantitySelected={setQuantitySelected}
            setPayout={setPayout}
            singleTicketPrice={SINGLE_TICKET_PRICE}
          />
        </S.SectionMargin>

        <Separator />
        <S.SectionMargin>
          <S.Testimonials>
            <Text variant="headline-h3" as="h3" semiBold>
              Quem já está ganhando
            </Text>
            <TestimonialsCarousel testimonials={testimonialsDataRandom} />
          </S.Testimonials>
        </S.SectionMargin>
      </S.Section>

      {renderStickFooter}

      {renderEligibilityBS}
    </S.Container>
  )
}
