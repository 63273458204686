import { PaymentTypeEnum } from 'common/enums/PaymentTypeEnum'
import { ReceiptStatusEnum } from 'common/enums/ReceiptStatus'

export const formPaymentEmpty = {
  title: 'Escolher forma de pagamento',
  subTitle: '',
  type: '',
}

export const formPaymentCredit = {
  title: 'Crédito Inter',
  subTitle: 'Não cobrado em ambiente de desenvolvimento.',
  type: PaymentTypeEnum.CREDIT_INTER,
}

export const formPaymentDebit = {
  title: 'Débito Inter',
  subTitle: 'Não cobrado em ambiente de desenvolvimento.',
  type: PaymentTypeEnum.DEBIT,
}

export const mockReceiptCheckout = {
  status: ReceiptStatusEnum.SUCCESS,
  saleInfo: {
    quantity: 10,
    amount: 1.5,
    paymentType: 'CREDIT_INTER',
    paymentDate: '2024-09-18T18:16:27',
  },
}
