export enum PaymentTypeEnum {
  DEBIT = 'DEBIT',
  CREDIT_INTER = 'CREDIT_INTER',
  LOYALTY = 'LOYALTY',
}

export const paymentDictionary = {
  [PaymentTypeEnum.DEBIT]: 'Débito Inter',
  [PaymentTypeEnum.CREDIT_INTER]: 'Crédito Inter',
  [PaymentTypeEnum.LOYALTY]: 'Inter Loop',
}
