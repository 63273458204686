import { useEffect, useState, useRef } from 'react'

import { PaymentMethod } from 'components'
import useDefaultPayment from 'hooks/useDefaultPaymentMethod'
import useBoundState from 'store'
import { PaymentTypeEnum } from 'common/enums/PaymentTypeEnum'

import { Text } from '@interco/inter-ui/components/Text'
import { Flex } from '@interco/inter-ui/components/Flex'
import { Counter } from '@interco/inter-ui/components/Counter'
import { formatToBRL } from '@interco/lib-util'

import * as S from './Home.styles'

interface HomePaymentProps {
  singleTicketPrice: number
  payout: number
  setPayout: React.Dispatch<React.SetStateAction<number>>
  quantitySelected: number
  setQuantitySelected: React.Dispatch<React.SetStateAction<number>>
}

const HomePayment = ({
  singleTicketPrice,
  payout,
  setPayout,
  quantitySelected,
  setQuantitySelected,
}: HomePaymentProps) => {
  const { getDefaultPaymentMethod } = useDefaultPayment()
  const setIsLoading = useBoundState((state) => state.setIsLoading)
  const [canCallDefaultPayment, setCanCallDefaultPayment] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const formOfPayment = useBoundState((state) => state.formOfPayment)
  const emptyFormOfPayment = useBoundState((state) => state.emptyFormOfPayment)

  useEffect(() => {
    if (canCallDefaultPayment) {
      getDefaultPaymentMethod(quantitySelected, payout)
    }
    // Keep only canCallDefaultPayment in dependencies array
  }, [canCallDefaultPayment])

  const handleUpdateCounter = (amount: number) => {
    const DELAY_CALL_DEFAULT_PAYMENT = 600
    setCanCallDefaultPayment(false)

    const newPayout = amount * singleTicketPrice
    setQuantitySelected(amount)
    setPayout(newPayout)

    if (formOfPayment.type === PaymentTypeEnum.DEBIT) {
      setIsLoading(true)

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        setCanCallDefaultPayment(true)
      }, DELAY_CALL_DEFAULT_PAYMENT)
    } else {
      emptyFormOfPayment()
      setIsLoading(false)
    }
  }

  const renderCounter = (
    <Counter
      min={1}
      max={10}
      value={quantitySelected}
      onUpdate={(amount) => handleUpdateCounter(amount)}
    />
  )

  const renderSummary = (
    <>
      <Flex key={1} direction="row" alignItems="center">
        <Text variant="body-3">Quantidade</Text>
        {renderCounter}
      </Flex>
      <Flex key={2} direction="row" alignItems="center">
        <Text variant="body-3">Sortezinha</Text>
        <Text variant="body-3" bold colorWeight={500}>
          {formatToBRL(singleTicketPrice)}
        </Text>
      </Flex>
    </>
  )
  return (
    <>
      <S.Summary>
        <Text variant="headline-h3" as="h3" semiBold>
          Resumo
        </Text>

        {renderSummary}
      </S.Summary>

      <S.Payment>
        <Text variant="headline-h3" as="h3" semiBold>
          Como você quer pagar?
        </Text>
        <PaymentMethod amount={payout} qtdTicket={quantitySelected} />
      </S.Payment>
    </>
  )
}

export default HomePayment
