import { PaymentTypeEnum } from 'common/enums/PaymentTypeEnum'
import { formPaymentEmpty } from 'common/mocks/Checkout'
import { CheckoutBaseBridge } from 'config/bridge/CheckoutBaseBridge'
import NewRelicUtils from 'config/monitoring/NewRelicUtils'
import useBoundState from 'store'

export const useDefaultPayment = () => {
  const formOfPayment = useBoundState((state) => state.formOfPayment)
  const setFormOfPayment = useBoundState((state) => state.setFormOfPayment)
  const setIsLoading = useBoundState((state) => state.setIsLoading)
  const emptyFormOfPayment = useBoundState((state) => state.emptyFormOfPayment)

  const { utmCampaign } = useBoundState.getState()

  const getDefaultPaymentMethod = async (quantity: number, amount: number) => {
    setIsLoading(true)

    if (formOfPayment.type !== PaymentTypeEnum.DEBIT) {
      emptyFormOfPayment()
      setIsLoading(false)
      return
    }

    const additionalFields = {
      number: `${quantity}`,
      productName: 'DEFAULT',
      utmCampaign,
    }

    try {
      const response = await CheckoutBaseBridge.defaultPaymentMethod(
        `${amount}`,
        'CAPITALIZATION',
        additionalFields,
        additionalFields,
      )
      setIsLoading(false)

      setFormOfPayment({
        title: response.title || formPaymentEmpty.title,
        subTitle: response.subTitle || formPaymentEmpty.subTitle,
        type: response.type || formPaymentEmpty.type,
      })
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'useDefaultPayment.getDefaultPaymentMethod',
      })

      setFormOfPayment({
        title: 'Formas de pagamento',
        subTitle: '',
        type: '',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return { getDefaultPaymentMethod, emptyFormOfPayment }
}

export default useDefaultPayment
