import { useEffect } from 'react'

import { SectionTagTypeEnum, SectionTypeEnum } from 'common/enums/AfterSales'
import { IAfterSalesTicket } from 'store/afterSales/types'
import { ListDescription } from 'components'
import { Icons } from 'utils/icons'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import { useHistory } from 'react-router-dom'

import { Flex } from '@interco/inter-ui/components/Flex'
import { Text } from '@interco/inter-ui/components/Text'
import { Loading } from '@interco/inter-ui/components/Loading'

import * as S from '../AfterSales.styles'
import { AnalyticsTicketFlow } from '../AfterSales.tags'

interface IProps {
  availableTickets: IAfterSalesTicket[]
  processingTickets?: number
}

export const AvailableTicketsSection = ({ availableTickets, processingTickets }: IProps) => {
  const history = useHistory()
  const sectionTitle =
    availableTickets.length > 1 ? SectionTypeEnum.FOR_YOU_SCRATCH : SectionTypeEnum.AVAILABLE

  useEffect(() => {
    // As this tag is used for 1 or more ticket, we can't inform value and luckyNumber
    AnalyticsTicketFlow(SectionTagTypeEnum.AVAILABLE, '-', '-', '')
  }, [])

  const IconLeft = (
    <S.IconWrap $bgColor="var(--primary100)">
      <Icons.Gift color="var(--primary500)" width={24} height={24} />
    </S.IconWrap>
  )
  const chevronRight = <Icons.ChevronRight color="var(--primary500)" width={24} height={24} />

  const availableButtonTitle =
    availableTickets.length === 1 ? '1 Sortezinha' : `${availableTickets.length} Sortezinhas`
  const ticketListDescription = (
    <ListDescription
      margin="16px 0 0"
      iconLeft={IconLeft}
      iconRight={chevronRight}
      withBorder
      onClick={() => history.push(TypesRoutes.START)}
    >
      <Flex direction="column">
        <Text variant="body-2" colorWeight={500} bold>
          {availableButtonTitle}
        </Text>
      </Flex>
    </ListDescription>
  )

  const processingTicketListDescription = (
    <ListDescription
      margin="16px 0 0"
      iconLeft={
        <S.IconWrap $bgColor="var(--primary100)">
          <Loading width={24} height={24} />
        </S.IconWrap>
      }
      withBorder
    >
      <Flex direction="column">
        <Text variant="body-2" colorWeight={500} bold>
          {processingTickets === 1 ? '1 Sortezinha' : `${processingTickets} Sortezinhas`}
        </Text>
        <Text variant="caption-1">{SectionTypeEnum.PROCESSING}</Text>
      </Flex>
    </ListDescription>
  )

  if (availableTickets.length === 0 && !processingTickets) return <></>

  return (
    <S.Content>
      <Text variant="body-3">{sectionTitle}</Text>
      {availableTickets.length > 0 && ticketListDescription}
      {processingTickets && processingTicketListDescription}
    </S.Content>
  )
}
