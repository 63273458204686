import { useEffect } from 'react'

import { Svg } from 'assets/svg/svg'
import { ActionIconEnum } from 'common/enums/ActionIcon'
import { PageTitles } from 'common/enums/PageTitles'
import { ReceiptStatusEnum } from 'common/enums/ReceiptStatus'
import { useNavBar } from 'hooks/useNavBar'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { AlertSign, SuccessSign } from 'styles/commons'
import { formatWeekDayDate } from 'utils/commons'
import { Icons } from 'utils/icons'
import { paymentDictionary, PaymentTypeEnum } from 'common/enums/PaymentTypeEnum'

import { Button } from '@interco/inter-ui/components/Button'
import { Flex } from '@interco/inter-ui/components/Flex'
import { Tag } from '@interco/inter-ui/components/Tag'
import { Text } from '@interco/inter-ui/components/Text'
import { formatToBRL } from '@interco/lib-util'

import * as S from './Receipt.styles'
import { AnalyticsReceipt } from './Receipt.tags'

export const Receipt = () => {
  const history = useHistory()
  const checkoutResponse = useBoundState((state) => state.checkoutResponse)
  const getHome = useBoundState((state) => state.getHome)

  useNavBar({
    navbarTitle: PageTitles.SCRATCHCARD,
    actionIcons: {
      rightIcon: ActionIconEnum.HOME,
    },
  })

  const receiptIcon = {
    success: <Icons.CheckCircle width="32px" height="32px" color="var(--neutral-theme)" />,
    pending: <Icons.Clock width="32px" height="32px" color="var(--neutral-theme)" />,
    right: <Icons.ChevronRight width="16px" height="16px" color="var(--primary500)" />,
  }

  const SUCCESS_MSG = 'Compra realizada com sucesso!'
  const PROCESSING_MSG = 'Estamos processando a sua compra'
  const totalAmount = formatToBRL(checkoutResponse.saleInfo?.amount as number)
  const paymentMethod = paymentDictionary[checkoutResponse.saleInfo?.paymentType as PaymentTypeEnum]

  useEffect(() => {
    AnalyticsReceipt({
      ref_type: checkoutResponse.status === ReceiptStatusEnum.SUCCESS ? 'sucesso' : 'pedente',
      action_id:
        checkoutResponse.status === ReceiptStatusEnum.SUCCESS ? SUCCESS_MSG : PROCESSING_MSG,
      value: totalAmount,
      payment: paymentMethod,
    })
  }, [])

  useEffect(() => {
    getHome()
  }, [getHome])

  const receiptDataView = [
    {
      label: 'Quantidade',
      value: checkoutResponse.saleInfo?.quantity,
    },
    {
      label: 'Total',
      value: totalAmount,
    },
    {
      label: 'Forma de pagamento',
      value: paymentMethod,
    },
    {
      label: 'Data do pagamento',
      value: checkoutResponse.saleInfo?.paymentDate
        ? formatWeekDayDate(checkoutResponse.saleInfo.paymentDate)
        : '',
    },
  ]

  const renderSuccess = (
    <S.Header>
      <SuccessSign>{receiptIcon.success}</SuccessSign>
      <Text variant="headline-h2" as="h2" semiBold>
        {SUCCESS_MSG}
      </Text>
    </S.Header>
  )

  const renderProcess = (
    <S.Header>
      <AlertSign>{receiptIcon.pending}</AlertSign>
      <Text variant="headline-h2" as="h2" semiBold>
        {PROCESSING_MSG}
      </Text>
    </S.Header>
  )

  const renderData = (
    <S.Data>
      {receiptDataView.map((item) => (
        <Flex key={item.label} direction="row">
          <Text variant="caption-1">{item.label}</Text>
          <Text variant="caption-1" bold colorWeight={500}>
            {item.value}
          </Text>
        </Flex>
      ))}
    </S.Data>
  )

  const handleStartGame = () => {
    history.push(TypesRoutes.START)
  }

  const handleGoHome = () => {
    history.push(TypesRoutes.LANDING_PAGE_HOME)
  }

  const renderLuckCard = () => {
    const quantity = checkoutResponse.saleInfo?.quantity ?? 0

    return (
      <S.LuckCard>
        <Flex direction="row" gap="16px" alignItems="center">
          <div>{Svg.GiftGroup}</div>

          {checkoutResponse.status === ReceiptStatusEnum.SUCCESS ? (
            <Flex direction="column" gap="8px" onClick={handleStartGame}>
              <Text variant="body-3" colorWeight={500} bold>
                {quantity > 1 ? 'Suas Sortezinhas estão prontas' : 'Sua Sortezinha está pronta'}
              </Text>
              <Button variant="link" icon={receiptIcon.right}>
                Raspar
              </Button>
            </Flex>
          ) : (
            <Flex direction="column" gap="8px">
              <S.TagContent>
                <Tag theme="yellow">
                  {quantity > 1 ? 'Preparando suas sortezinhas' : 'Preparando sua sortezinha'}
                </Tag>
              </S.TagContent>
              <Text variant="caption-1" colorWeight={500}>
                Vamos te mandar uma notificação quando você já puder raspar.
              </Text>
            </Flex>
          )}
        </Flex>
      </S.LuckCard>
    )
  }

  const renderStickFooter =
    checkoutResponse.status === ReceiptStatusEnum.SUCCESS ? (
      <S.Footer>
        <Button fullWidth onClick={handleStartGame}>
          Raspar agora
        </Button>
        <Button fullWidth variant="secondary" onClick={handleGoHome}>
          Voltar para a home
        </Button>
      </S.Footer>
    ) : (
      <S.Footer>
        <Button fullWidth onClick={handleGoHome}>
          Voltar para a home
        </Button>
      </S.Footer>
    )

  const renderHeader: Record<ReceiptStatusEnum, JSX.Element | undefined> = {
    SUCCESS: renderSuccess,
    PROCESSING: renderProcess,
    ERROR: undefined,
  }

  return (
    <S.Container>
      {renderHeader[checkoutResponse.status]}

      {renderData}

      {renderLuckCard()}

      {renderStickFooter}
    </S.Container>
  )
}
