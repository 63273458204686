import { useEffect } from 'react'

import { Svg } from 'assets/svg/svg'

import * as C from './Counter.styles'
import * as S from './PrizeCounter.styles'
import { runCounter } from './counterScript'

interface IProps {
  countNumber: number
  isDarkMode: boolean
}

const PrizeCounter = ({ countNumber, isDarkMode }: IProps) => {
  useEffect(() => {
    runCounter(countNumber, true)
  }, [countNumber])

  return (
    <C.HeroContainer data-testid="prize-counter">
      <S.HeroImage $isDarkMode={isDarkMode} />
      <S.HeroCounter className="poc-counter" />
      <S.HeroGift>{Svg.GiftSuccess}</S.HeroGift>
      <C.HeroCircle $top={53} $left={4} $delay={0.6}>
        {Svg.TicketCounterCircleGreen}
      </C.HeroCircle>
      <C.HeroCircle $top={85} $left={54} $delay={0.5}>
        {Svg.TicketCounterCircleOrange}
      </C.HeroCircle>
      <C.HeroCircle $top={55} $left={89} $delay={0.2}>
        {Svg.TicketCounterCircleBlue}
      </C.HeroCircle>
    </C.HeroContainer>
  )
}

export default PrizeCounter
