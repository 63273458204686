import { useCallback } from 'react'

import { DeepLinksEnum } from 'common/enums/DeepLink'
import { useHistory, useLocation } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'

export function PathQueryParams() {
  const location = useLocation()
  const history = useHistory()

  const getTickets = useBoundState((state) => state.getTickets)
  const getHome = useBoundState((state) => state.getHome)
  const setQueryParams = useBoundState((state) => state.setQueryParams)
  const setUtmCampaign = useBoundState((state) => state.setUtmCampaign)

  const whitelist = ['/']

  const handler = () => {
    if (whitelist.includes(location.pathname)) {
      const searchParams = new URLSearchParams(location.search)

      return setPathParams(location.pathname, searchParams)
    }

    return false
  }

  const handleTransmission = useCallback(
    (noTickets: boolean) => {
      noTickets
        ? getHome({ history, pathname: TypesRoutes.LANDING_PAGE_HOME })
        : history.push(TypesRoutes.AFTER_SALES)
    },
    [getHome, history],
  )

  const setPathParams = (pathname: string, params: URLSearchParams) => {
    let hasParams = false

    if (params.getAll.length > 0 && pathname === '/') {
      const urlParams = {
        url: params.get('url') || undefined,
        utm_campaign: params.get('utm_campaign') || undefined,
      }

      if (urlParams.utm_campaign) {
        setUtmCampaign(urlParams.utm_campaign)
      }

      if (urlParams.url && urlParams.url === DeepLinksEnum.AFTER_SALES) {
        getTickets({ onTransmission: handleTransmission })

        setQueryParams(urlParams.url)
        hasParams = true
      }

      if (urlParams.url && urlParams.url === DeepLinksEnum.LANDING_PAGE) {
        getHome({
          history,
          pathname: TypesRoutes.LANDING_PAGE_HOME,
        })

        setQueryParams(urlParams.url)
        hasParams = true
      }
    }

    return hasParams
  }

  return handler
}
