export enum SectionTypeEnum {
  PROCESSING = 'Em processamento',
  AVAILABLE = 'Disponível',
  FOR_YOU_SCRATCH = 'Pra você raspar',
  ACTIVE = 'Com sorteios ativos',
  COMPLETED = 'Finalizados',
}

export enum SectionTagTypeEnum {
  AVAILABLE = 'Disponível',
  ACTIVE = 'Ativo',
  COMPLETED = 'Finalizado',
}

export enum rafflesEnum {
  REALIZED = 'REALIZED',
  NEXT = 'NEXT',
  SOON = 'SOON',
  WIN = 'WIN',
}
